import styled, { css, keyframes } from 'styled-components';
import Img from 'gatsby-image';
import { fonts, colors } from '../../../../../globals';

const slideUp = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 70px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const animationTop = ({ visible }) => {
  if (visible) {
    return css`
      ${slideUp} 0.8s cubic-bezier(0.78, 0, 0.235, 1) 200ms forwards
    `;
  }
  return null;
};
const animationBottom = ({ visible }) => {
  if (visible) {
    return css`
      ${slideUp} 0.8s cubic-bezier(0.78, 0, 0.235, 1) 400ms forwards
    `;
  }
  return null;
};
const animationTopSecondary = ({ visible }) => {
  if (visible) {
    return css`
      ${slideUp} 1.2s cubic-bezier(0.78, 0, 0.235, 1) 200ms forwards
    `;
  }
  return null;
};

export const ColumnContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 1.5rem;
  text-align: left;


  & > h2 {
    ${{ ...fonts.headline_1 }}
    ${(props) => (props.dark ? { ...colors.dark } : { ...colors.light })}
    position: relative;
    opacity: 0;
    top: 60px;
    animation: ${animationTop};
  }

  & > h3 {
    ${{ ...fonts.headline_3a }}
    ${(props) => (props.dark ? { ...colors.dark } : { ...colors.light })}
    position: relative;
    opacity: 0;
    margin-bottom: 2.43rem;
    animation: ${animationTopSecondary};
    width: 140%;
    font-size: 2.5rem;
    line-height: 1.2;
  }

  & > p, & > div > p {
    ${{ ...fonts.paragraf }}
    ${(props) => (props.dark ? { ...colors.dark } : { ...colors.light })}
    max-width: 398px;
    display: inline;
  }
  span {
    font-size: 1rem;
    line-height: 1.62rem;
    letter-spacing: 0.03rem;
  }
`;

export const Wrapper = styled.div`
width: 100%;
max-zoom: 40%;
position: relative;
opacity: 0;
animation: ${animationBottom};
`;

export const NameText = styled.h3`
    font-family: 'Abril Fatface', 'Prata';
    font-size: 2rem;
    color: #fdfdfd;
    font-weight: 400;
    margin-top: 2rem;
  `;

export const ItemWrapper = styled.div`
    text-align: center;
    width: 300px;
  `;

export const ResponsiveImage = styled(Img)`
flex: 100%;
& img {
  object-fit: contain !important;
}
`;


export const ImageWrapper = styled.div`
  position: relative;
`;
