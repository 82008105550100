import styled from 'styled-components';

export const InfoDotContainer = styled.div`
  position: absolute;
  cursor: pointer;
  ${({ positionLeft }) => positionLeft && `
    left: ${positionLeft};
 `}
  ${({ positionTop }) => positionTop && `
     top: ${positionTop};
  `}
`;

export const InfoIcon = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 1.25rem;
  height: 1.25rem;
  font-family: "Red Hat Display", sans-serif;
  border-radius: 100%;
  color: white;
  background-color: #de2831;
  font-weight: 700;
  font-size: 1rem;
  margin: 0 5px;
`;
