import React, { useState, useEffect } from 'react';
import CountUp from 'react-countup';
import { CounterContainer, NumberContainer } from './styles';

const Counter = ({
  number, unit, children, separator, dark, style, visible,
}) => {
  const [isCount, setCount] = useState(visible);
  useEffect(() => {
    if (visible) setCount(true);
  }, [visible]);
  return (
    <CounterContainer separator={separator} dark={dark} style={style}>
      <NumberContainer>
        {isCount ? <CountUp end={number} duration={3} /> : '0'}
        {' '}
        {unit}
      </NumberContainer>
      {children}
    </CounterContainer>
  );
};

export default Counter;
