import styled from 'styled-components';
import { colors } from '../../../../../globals';
import '../../../../../static/fonts/brygada.css';

export const Container = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    font-family: 'Brygada 1918';
    color: ${colors.light.color};
    font-size: 1.4rem;
    justify-content: space-evenly;
    height: 65%;
`;
