import React from 'react';
import {
  InfoDotContainer, InfoIcon,
} from './styles';
import { useDispatch } from 'react-redux';
import actions from 'src/store/actions';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';

const renderModal = (body) => (
  <MDXProvider>
    <MDXRenderer>
      {body}
    </MDXRenderer>
  </MDXProvider>
);

const modalHandler = (modalImg, modalBody, dispatch) => {
  dispatch({ type: actions.CLEAR_MODAL });
  dispatch({ type: actions.SET_MODAL_TYPE, payload: 'vertical' });
  dispatch({ type: actions.SET_MODAL_CONTENTS, payload: renderModal(modalBody) });
  dispatch({ type: actions.SET_MODAL_IMAGE, payload: modalImg });
  dispatch({ type: actions.SET_SHOW_MODAL, payload: true });
};

const InfoDot = ({
  positionLeft, positionTop, clickDot, insideText, modalImg, modalBody
}) => {

  const dispatch = useDispatch();

  const openModalContainer = (event) => {
    if (event.key === 'Enter') {
      clickHandler();
    }
  };

  const clickHandler = () => {
    modalImg || modalBody ? modalHandler(modalImg, modalBody, dispatch) : clickDot();
  }

  return (

    <InfoDotContainer
      positionLeft={positionLeft}
      positionTop={positionTop}
      onClick={clickHandler}
      onKeyDown={openModalContainer}
      role="button"
      tabindex="0"
      aria-label={insideText}
    >
      <InfoIcon>i</InfoIcon>
    </InfoDotContainer>

  );
};
export default InfoDot;
