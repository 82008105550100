import React from 'react';
import { ParallaxMordo, Mordo } from 'src/components/desktop/parallax/ParallaxMordo.jsx';
import { useStaticQuery, graphql } from 'gatsby';
import PhotoWithDescription from 'src/components/desktop/photoWithDescription/photoWithDescription';
import { SlideLarge } from '../../_styles';


const Slide = ({ query }) => {
  const assetQuery = useStaticQuery(graphql`
  query {
    mainscene: file(relativePath: { eq: "chapter_4/sub_8/4-8-3-bg.jpg"}) {
      childImageSharp {
          fluid(maxWidth: 1920, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
  },
  person1: file(relativePath: { eq: "chapter_4/sub_8/4-8-3-people1.png"}) {
    childImageSharp {
        fixed(width: 300, quality: 90) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
},
  person2: file(relativePath: { eq: "chapter_4/sub_8/4-8-3-people2.png"}) {
    childImageSharp {
        fixed(width: 300, quality: 90) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
},
  person3: file(relativePath: { eq: "chapter_4/sub_8/4-8-3-people3.png"}) {
    childImageSharp {
        fixed(width: 300, quality: 90) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
},
modal4831: file(relativePath: { eq: "chapter_4/sub_8/4-8-3-modal1.jpg"}) {
    childImageSharp {
      fixed(width: 506, height: 313)  {
        ...GatsbyImageSharpFixed_withWebp_noBase64
        width
      }
    }
  },
  modal4832: file(relativePath: { eq: "chapter_4/sub_8/4-8-3-modal2.jpg"}) {
    childImageSharp {
      fixed(width: 506, height: 313)  {
        ...GatsbyImageSharpFixed_withWebp_noBase64
        width
      }
    }
  },
  modal4833: file(relativePath: { eq: "chapter_4/sub_8/4-8-3-modal3.jpg"}) {
    childImageSharp {
      fixed(width: 506, height: 313)  {
        ...GatsbyImageSharpFixed_withWebp_noBase64
        width
      }
    }
  },
  }
  `);

  return (
    <SlideLarge>
      <ParallaxMordo>
        <Mordo
          mainscene
          move={{ x: 20, y: 10 }}
          background={{ image: assetQuery.mainscene.childImageSharp.fluid }}
        />
        <Mordo
          scene={2}
          move={{ x: -5, y: -5 }}
          background={{ color: 'transparent' }}
          flexi
          customStyle={{
            width: '90vw', margin: '25vh auto 0', maxWidth: '1200px', justifyContent: 'space-around',
          }}
        >
          <PhotoWithDescription
            image={assetQuery.person1.childImageSharp.fixed}
            name={query.modal4831.frontmatter.name}
            modalImg={assetQuery.modal4831.childImageSharp.fixed}
            modalBody={query.modal4831.body}
          />
          <PhotoWithDescription
            image={assetQuery.person2.childImageSharp.fixed}
            name={query.modal4832.frontmatter.name}
            modalImg={assetQuery.modal4832.childImageSharp.fixed}
            modalBody={query.modal4832.body}
          />
          <PhotoWithDescription
            image={assetQuery.person3.childImageSharp.fixed}
            name={query.modal4833.frontmatter.name}
            modalImg={assetQuery.modal4833.childImageSharp.fixed}
            modalBody={query.modal4833.body}
          />
        </Mordo>
      </ParallaxMordo>
    </SlideLarge>

  );
};


export default Slide;
