import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { ParallaxMordo, Mordo } from 'src/components/desktop/parallax/ParallaxMordo.jsx';
import Caption from 'src/components/desktop/caption/caption';
import PhotoWithDescription from 'src/components/desktop/photoWithDescription/photoWithDescription';
import { SlideXLarge } from '../../_styles';

const Slide = ({ query }) => {
  const assetQuery = useStaticQuery(graphql`
  query {
    mainscene: file(relativePath: { eq: "chapter_4/sub_8/4-8-4-bg.jpg"}) {
      childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
  },
  photo1: file(relativePath: {eq: "chapter_4/sub_8/4-8-4-photo1.png"}) {
    childImageSharp {
      fixed(width: 300) {
        ...GatsbyImageSharpFixed_withWebp_noBase64
      }
    }
  },
  photo2: file(relativePath: {eq: "chapter_4/sub_8/4-8-4-photo2.png"}) {
    childImageSharp {
      fixed(width: 300) {
        ...GatsbyImageSharpFixed_withWebp_noBase64
      }
    }
  },
  photo3: file(relativePath: {eq: "chapter_4/sub_8/4-8-4-photo3.png"}) {
    childImageSharp {
      fixed(width: 300) {
        ...GatsbyImageSharpFixed_withWebp_noBase64
      }
    }
  },
  }
`);

  return (
    <SlideXLarge style={{ padding: 0 }}>
      <ParallaxMordo column>
        <Mordo
          mainscene
          move={{ x: 10, y: 10 }}
          background={{ image: assetQuery.mainscene.childImageSharp.fluid }}
        />
        <Mordo
          scenelayer
          flexi={{ flex: '0 0 35%' }}
          scene={1}
          move={{ x: -5, y: -5 }}
        >
          <Caption body={query.caption.body} dark specialSize="1.25" />
        </Mordo>
        <Mordo
          scene={2}
          move={{ x: -5, y: -5 }}
          background={{ color: 'transparent' }}
          flexi
          customStyle={{
            width: '90vw', margin: '23vh auto 0', paddingLeft: '8vw', maxWidth: '1200px', justifyContent: 'space-around',
          }}
        >
          <PhotoWithDescription
            image={assetQuery.photo1.childImageSharp.fixed}
            body={query.col1.body}
            dark
          />
          <PhotoWithDescription
            image={assetQuery.photo2.childImageSharp.fixed}
            body={query.col2.body}
            dark
          />
          <PhotoWithDescription
            image={assetQuery.photo3.childImageSharp.fixed}
            body={query.col3.body}
            dark
          />
        </Mordo>
      </ParallaxMordo>
    </SlideXLarge>
  );
};

export default Slide;
