import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Template from 'src/slides/desktop/_templates/chapter_4/sub_8/slide3';

const Slide = () => {
  const query = useStaticQuery(graphql`
  query {
    leftColumn: mdx(frontmatter: {language: {eq: "HU"}, title: {eq: "slide-4-12-6"}}) {
        body
      },
      hoverDot: mdx(
        frontmatter: {
          language: { eq: "HU" }
          title: { eq: "organizations-dots" }
        }
      ) {
        exports {
          hoverInfo {
            positionLeft
            positionTop
            identifier
          }
        }
      },
      modal4831:  mdx(frontmatter: {language: {eq: "HU"}, title: {eq: "organizations-modals"}, selector: {eq: "modal4831"}}) {
        body
        frontmatter {
          name
        }
      },
      modal4832:  mdx(frontmatter: {language: {eq: "HU"}, title: {eq: "organizations-modals"}, selector: {eq: "modal4832"}}) {
        body
        frontmatter {
          name
        }
      },
      modal4833:  mdx(frontmatter: {language: {eq: "HU"}, title: {eq: "organizations-modals"}, selector: {eq: "modal4833"}}) {
        body
        frontmatter {
          name
        }
      },
  }
  `);
  return (
    <Template query={query} />
  );
};


export default Slide;
