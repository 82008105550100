import React from 'react';
import BackgroundPhoto from 'src/components/mobile/backgroundPhoto/backgroundPhoto';
import TextAnimation from 'src/components/mobile/textAnimation/textAnimation';
import CounterStats from 'src/components/mobile/counterStats/counterStats';
import AudioPlayer from 'src/components/desktop/audioPlayer/audioPlayer';
import { getLangFromPath } from 'src/utils';
import { BlockContainer } from '../../_styles';
import { mediaServer } from '../../../../../../globals';

const Slide = ({ query, audio = 'Tadeusz Pełczyński' }) => (
  <BlockContainer height="2500px">
    <BackgroundPhoto fixed={query.file.childImageSharp.fixed} alt="slide-photo">
      <TextAnimation
        containerStyle={{
          height: '11%',
          marginTop: '3rem',
          textAlign: 'center',
        }}
        body={query.mdx.body}
      />
      <CounterStats query={query} />
      <AudioPlayer
        audio={[`${mediaServer}/webm/${getLangFromPath().toLowerCase()}/482pelczynski.webm`, `${mediaServer}/mp4/${getLangFromPath().toLowerCase()}/482pelczynski.mp4`]}
        style={{ margin: '6rem auto' }}
        audioImage={query.audioImage.childImageSharp.fluid}
        audioDescription={audio}
        size={2}
      />
    </BackgroundPhoto>
  </BlockContainer>
);

export default Slide;
