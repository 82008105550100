import React from 'react';
import CountUp from 'react-countup';
import { CounterContainer, NumberContainer } from './styles';

const Counter = ({
  number, unit, children, separator, dark, style,
}) => (
  <CounterContainer separator={separator} dark={dark} style={style}>
    <NumberContainer>
      <CountUp end={number} duration={3} delay={0.8} />
      {' '}
      {unit}
    </NumberContainer>
    {children}
  </CounterContainer>
);

export default Counter;
