/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import { colors } from '../../../../../globals';
import '../../../../../static/fonts/brygada.css';

export const CounterContainer = styled.div`
    font-family: 'Brygada 1918';
    font-size: 1.4rem;
    ${(props) => (props.dark ? colors.dark : colors.light)};
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: ${(props) => (props.separator ? props.dark ? `1px solid ${colors.dark.color}` : `1px solid ${colors.light.color}` : 'none')};
    padding:  0 3rem;
    text-align: center;
`;

export const NumberContainer = styled.div`
    font-size: 4rem;
`;
