import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Template from 'src/slides/desktop/_templates/chapter_4/sub_8/slide2';

const Slide = () => {
  const query = useStaticQuery(graphql`
    query {
      caption: mdx(frontmatter: {language: {eq: "HU"}, title: {eq: "slide-4-8-2"}}) {
        body
      },
      labels: mdx(frontmatter: {language: {eq: "HU"}, title: {eq: "slide-4-8-2-labels"}}) {
        exports {
        labels {
          header
          district
          warsaw
          lublin
          krakow
          sub1
          sub2
          sub3
          sub4
          sub5
          sub6
        }
      }
      },
      audio: mdx(frontmatter: {language: {eq: "HU"}, title: {eq: "slide-4-8-2-audio"}}) {
        excerpt(pruneLength: 10000)
      },
    }
  `);
  return (
    <Template query={query} />
  );
};


export default Slide;
