import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import BackgroundPhoto from 'src/components/mobile/backgroundPhoto/backgroundPhoto';
import PhotoWithDescription from 'src/components/mobile/photoWithDescription/photoWithDescription';
import { BlockContainer } from '../../_styles';

const Slide = ({ query }) => {
  const assetQuery = useStaticQuery(graphql`
    query {
      person1: file(relativePath: { eq: "chapter_4/sub_8/4-8-3-people1.png" }) {
        childImageSharp {
          fixed(width: 300, quality: 90) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      person2: file(relativePath: { eq: "chapter_4/sub_8/4-8-3-people2.png" }) {
        childImageSharp {
          fixed(width: 300, quality: 90) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      person3: file(relativePath: { eq: "chapter_4/sub_8/4-8-3-people3.png" }) {
        childImageSharp {
          fixed(width: 300, quality: 90) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      modal4831: file(relativePath: { eq: "chapter_4/sub_8/4-8-3-modal1.jpg"}) {
        childImageSharp {
          fixed(width: 506, height: 313)  {
            ...GatsbyImageSharpFixed_withWebp_noBase64
            width
          }
        }
      },
      modal4832: file(relativePath: { eq: "chapter_4/sub_8/4-8-3-modal2.jpg"}) {
        childImageSharp {
          fixed(width: 506, height: 313)  {
            ...GatsbyImageSharpFixed_withWebp_noBase64
            width
          }
        }
      },
      modal4833: file(relativePath: { eq: "chapter_4/sub_8/4-8-3-modal3.jpg"}) {
        childImageSharp {
          fixed(width: 506, height: 313)  {
            ...GatsbyImageSharpFixed_withWebp_noBase64
            width
          }
        }
      },
    }
  `);
  return (
    <BlockContainer height="1200px">
      <BackgroundPhoto
        fixed={query.file.childImageSharp.fixed}
        alt="slide-photo"
      >
        <PhotoWithDescription
          image={assetQuery.person1.childImageSharp.fixed}
          name={query.modal4831.frontmatter.name}
          bodyModal={query.modal4831.body}
          imgModal={assetQuery.modal4831.childImageSharp.fixed}
        />
        <PhotoWithDescription
          image={assetQuery.person2.childImageSharp.fixed}
          name={query.modal4832.frontmatter.name}
          bodyModal={query.modal4832.body}
          imgModal={assetQuery.modal4832.childImageSharp.fixed}
        />
        <PhotoWithDescription
          image={assetQuery.person3.childImageSharp.fixed}
          name={query.modal4833.frontmatter.name}
          bodyModal={query.modal4833.body}
          imgModal={assetQuery.modal4833.childImageSharp.fixed}
        />
      </BackgroundPhoto>
    </BlockContainer>
  );
};

export default Slide;
