import React from 'react';
import { useDispatch } from 'react-redux';
import actions from 'src/store/actions';
import { useInView } from 'react-intersection-observer';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import InfoDot from 'src/components/desktop/infoDot/infoDot';

import Hyperlink from 'src/components/_shared/hyperlink/hyperlink';
import {
  ColumnContent, Wrapper, NameText, ItemWrapper, ResponsiveImage, ImageContainer, FlagImage,
} from './styles';

const PhotoWithDescription = ({
  body, name, image, dark, style, column, bodyModal, imgModal, flagImg,
}) => {
  const dispatch = useDispatch();
  const [ref, inView] = useInView({
    threshold: 0.5,
  });

  const renderModal = (modalText) => (
    <MDXProvider>
      <MDXRenderer>
        {modalText}
      </MDXRenderer>
    </MDXProvider>
  );

  const clickHandler = () => {
    dispatch({ type: actions.CLEAR_MODAL });
    dispatch({ type: actions.SET_MODAL_TYPE, payload: 'mobile' });
    dispatch({ type: actions.SET_MODAL_CONTENTS, payload: renderModal(bodyModal) });
    dispatch({ type: actions.SET_MODAL_IMAGE, payload: imgModal });
    dispatch({ type: actions.SET_SHOW_MODAL, payload: true });
  };

  return (

    <ItemWrapper ref={ref}>
      <ImageContainer visible={inView}>
        <ResponsiveImage
          fixed={image}
        />
        <InfoDot insideText="modal" positionLeft="65%" positionTop="70%" clickDot={() => clickHandler()} />
      </ImageContainer>
      {flagImg && <FlagImage fixed={flagImg} />}
      {name && <NameText visible={inView} style={{ marginTop: flagImg && '-1rem' }}>{name}</NameText>}
      {body && (
      <ColumnContent dark={dark} visible={inView} style={style} column={column}>
        <MDXProvider components={{
          Wrapper,
          Hyperlink,
          p: (props) => <p {...props} tabIndex="0" />,
        }}
        >
          <MDXRenderer visible={inView}>{body}</MDXRenderer>
        </MDXProvider>
      </ColumnContent>
      )}
    </ItemWrapper>
  );
};

export default PhotoWithDescription;
