import React from 'react';
import {
  ParallaxMordo,
  Mordo,
} from 'src/components/desktop/parallax/ParallaxMordo.jsx';
import { useStaticQuery, graphql } from 'gatsby';
import { useInView } from 'react-intersection-observer';
import AudioPlayer from 'src/components/desktop/audioPlayer/audioPlayer';
import Counter from 'src/components/desktop/counter/counter';
import Caption from 'src/components/desktop/caption/caption';
import styled, { keyframes } from 'styled-components';
import { getLangFromPath } from 'src/utils';
import { SlideLarge } from '../../_styles';
import { colors, mediaServer } from '../../../../../../globals';

const slideUp = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0,100%,0);
  }
  to {
    opacity: 1;
    transform: translate3d(0,0,0);
  }
`;


const GridContainer = styled.div`
  display: grid;
  grid-template: 1fr 1fr 2fr / repeat(6, 1fr);
  opacity: 0;
  transform: translate3d(0,100%,0);
  animation: ${slideUp} 0.8s cubic-bezier(0.78, 0, 0.235, 1) 500ms forwards;
`;


const Slide = ({ query }) => {
  const [ref, inView] = useInView({
    threshold: 0.5,
  });

  const assetQuery = useStaticQuery(graphql`
    query {
      mainscene: file(relativePath: { eq: "chapter_4/sub_8/4-8-2-bg.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      audioImage: file(
        relativePath: { eq: "chapter_4/sub_8/4-8-2-audio.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 275) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);


  const headerStyle = {
    textAlign: 'center',
    color: colors.light.color,
    fontFamily: 'Brygada 1918',
    fontSize: '3rem',
    gridArea: '1 / 1 / 2 / 7',
  };

  const subStyle = {
    ...headerStyle,
    gridArea: 'auto / auto / auto / span 2',
  };

  const districtStyle = {
    ...headerStyle,
    fontSize: '1rem',
  };

  const cityStyle = {
    ...headerStyle,
    fontSize: '1.7rem',
  };

  const {
    header, district, warsaw, lublin, krakow, sub1, sub2, sub3, sub4, sub5, sub6,
  } = query.labels.exports.labels;

  return (
    <SlideLarge ref={ref}>
      <ParallaxMordo>
        <Mordo
          mainscene
          move={{ x: -20, y: -10 }}
          background={{ image: assetQuery.mainscene.childImageSharp.fluid }}
        />

        <Mordo scenelayer flexi scene={1} move={{ x: 10, y: 20 }}>
          <div css={{ marginLeft: '7vw', marginTop: '20vh', marginRight: '3vw' }}>
            <Caption
              body={query.caption.body}
              specialSize="1.25"
              style={{
                height: 'auto', padding: 0, fontFamily: 'Brygada 1918', marginBottom: '5vh',
              }}
            />
            {inView && (
              <GridContainer visible={inView}>
                <div style={headerStyle}>{header}</div>
                <div style={subStyle}>
                  <p style={districtStyle}>{district}</p>
                  <p style={cityStyle}>{warsaw}</p>
                </div>
                <div style={subStyle}>
                  <p style={districtStyle}>{district}</p>
                  <p style={cityStyle}>{lublin}</p>
                </div>
                <div style={subStyle}>
                  <p style={districtStyle}>{district}</p>
                  <p style={cityStyle}>{krakow}</p>
                </div>
                <Counter number={103} separator>{sub1}</Counter>
                <Counter number={40}>{sub2}</Counter>
                <Counter number={37} separator>{sub3}</Counter>
                <Counter number={36}>{sub4}</Counter>
                <Counter number={76} separator>{sub5}</Counter>
                <Counter number={50}>{sub6}</Counter>
              </GridContainer>
            )}
          </div>
        </Mordo>
        <Mordo
          scene={4}
          move={{ x: -20, y: -10 }}
        >
          <div css={{
            width: '34vw', height: '100%', marginLeft: '81vw',
          }}
          >

            <AudioPlayer audio={[`${mediaServer}/webm/${getLangFromPath().toLowerCase()}/482pelczynski.webm`, `${mediaServer}/mp4/${getLangFromPath().toLowerCase()}/482pelczynski.mp4`]} audioDescription={query.audio.excerpt} size={1} audioImage={assetQuery.audioImage.childImageSharp.fluid} style={{ justifyContent: 'center' }} />
          </div>

        </Mordo>
      </ParallaxMordo>
    </SlideLarge>
  );
};

export default Slide;
