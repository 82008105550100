import React from 'react';
import { ParallaxMordo, Mordo } from 'src/components/desktop/parallax/ParallaxMordo.jsx';
import { useStaticQuery, graphql } from 'gatsby';
import LeftColContent from 'src/components/desktop/leftColContent/leftColContent';
import BackgroundAnimation from 'src/components/desktop/backgroundAnimation/backgroundAnimation';
import Video from 'src/assets/videos/chapter_4/4-8-1-bg.webm';
import VideoMP4 from 'src/assets/videos/chapter_4/4-8-1-bg.mp4';
import { getLangFromPath } from 'src/utils';
import { SlideLarge } from '../../_styles';
import { mediaServer } from '../../../../../../globals';

const Slide = ({ query }) => {
  const assetQuery = useStaticQuery(graphql`
  query {
    photo1: file(relativePath: { eq: "chapter_4/sub_8/4-8-1-photo1.png"}) {
      childImageSharp {
          fluid(maxWidth: 586) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
  },
    photo2: file(relativePath: { eq: "chapter_4/sub_8/4-8-1-photo2.png"}) {
      childImageSharp {
          fluid(maxWidth: 657) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
  },
  audioImage: file(relativePath: { eq: "chapter_4/sub_8/4-8-1-audio.png"}) {
      childImageSharp {
          fluid(maxWidth: 275) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
  },
  }
  `);
  return (
    <SlideLarge>
      <ParallaxMordo>
        <Mordo
          mainscene
          move={{ x: -20, y: -10 }}
          background={{ color: 'transparent' }}
        >
          <BackgroundAnimation src={Video} mp4={VideoMP4} responsive />
        </Mordo>
        <Mordo
          scenelayer={{ size: '30%', fromLeft: '-20%' }}
          scene={1}
          move={{ x: -10, y: -5 }}
          background={{ image: assetQuery.photo1.childImageSharp.fluid }}
        />
        <Mordo
          scenelayer={{ size: '65%', fromLeft: '45%' }}
          scene={1}
          move={{ x: 5, y: 5 }}
          background={{ image: assetQuery.photo2.childImageSharp.fluid }}
        />

        <Mordo
          scenelayer
          scene={2}
          move={{ x: 10, y: 20 }}
        >
          <LeftColContent style={{ marginTop: '-12%' }} body={query.mdx.body} audio={[`${mediaServer}/webm/${getLangFromPath().toLowerCase()}/481werobej.webm`, `${mediaServer}/mp4/${getLangFromPath().toLowerCase()}/481werobej.mp4`]} audioImage={assetQuery.audioImage.childImageSharp.fluid} />
        </Mordo>
      </ParallaxMordo>
    </SlideLarge>

  );
};


export default Slide;
