import React from 'react';
import { useInView } from 'react-intersection-observer';
import Counter from 'src/components/mobile/counter/counter';
import { Container } from './styles';

const CounterStats = ({ query }) => {
  const {
    header,
    district,
    warsaw,
    lublin,
    krakow,
    sub1,
    sub2,
    sub3,
    sub4,
    sub5,
    sub6,
  } = query.labels.exports.labels;

  const [ref, inView] = useInView({
    threshold: 0,
  });
  const [ref2, inView2] = useInView({
    threshold: 0,
  });
  const [ref3, inView3] = useInView({
    threshold: 0,
  });

  return (
    <>
      <Container>
        <div style={{ fontSize: '0.875rem', margin: '1rem 0' }}>{header}</div>
        <div ref={ref}>
          <p>{district}</p>
          <p style={{ fontSize: '2rem' }}>{warsaw}</p>
        </div>
        <Counter number={103} visible={inView}>
          {sub1}
        </Counter>
        <Counter number={40} visible={inView}>{sub2}</Counter>
        <div style={{ marginTop: '1rem' }} ref={ref2}>
          <p>{district}</p>
          <p style={{ fontSize: '2rem' }}>{lublin}</p>
        </div>
        <Counter number={37} visible={inView2}>
          {sub3}
        </Counter>
        <Counter number={36} visible={inView2}>{sub4}</Counter>
        <div style={{ marginTop: '1rem' }} ref={ref3}>
          <p>{district}</p>
          <p style={{ fontSize: '2rem' }}>{krakow}</p>
        </div>
        <Counter number={76} visible={inView3}>
          {sub5}
        </Counter>
        <Counter number={50} visible={inView3}>{sub6}</Counter>
      </Container>
    </>
  );
};

export default CounterStats;
