import React from 'react';
import { useInView } from 'react-intersection-observer';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';

import Hyperlink from 'src/components/_shared/hyperlink/hyperlink';
import InfoDot from 'src/components/desktop/infoDot/infoDot.jsx'

import { ColumnContent, Wrapper, NameText, ItemWrapper, ResponsiveImage, ImageWrapper } from './styles';


const PhotoWithDescription = ({
  body, name, image, dark, style, column, modalImg, modalBody
}) => {

  const [ref, inView] = useInView({
    threshold: 0,
  });

  return (

    <ItemWrapper>
      <ImageWrapper>
        <ResponsiveImage
          fixed={image}
        />

        {modalImg || modalBody ?
          <InfoDot
            positionLeft='61%'
            positionTop='74%'
            modalImg={modalImg}
            modalBody={modalBody}
          /> : null}

      </ImageWrapper>
      {name && <NameText>{name}</NameText>}
      {body && (
        <ColumnContent dark={dark} ref={ref} visible={inView} style={style} column={column}>
          <MDXProvider components={{
            Wrapper,
            Hyperlink,
            p: (props) => <p {...props} tabIndex="0" />,
          }}
          >
            <MDXRenderer visible={inView}>{body}</MDXRenderer>
          </MDXProvider>
        </ColumnContent>
      )}
    </ItemWrapper>
  );
};

export default PhotoWithDescription;
