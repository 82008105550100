import React from 'react';
import BackgroundAnimation from 'src/components/mobile/backgroundAnimation/backgroundAnimation';
import TextAnimation from 'src/components/mobile/textAnimation/textAnimation';
import AudioPlayer from 'src/components/desktop/audioPlayer/audioPlayer';
import webm from 'src/assets/videos/chapter_4/4-8-1-mobile.webm';
import mp4 from 'src/assets/videos/chapter_4/4-8-1-mobile.mp4';
import { getLangFromPath } from 'src/utils';
import { BlockContainer, ChpaterTitle } from '../../_styles';
import { mediaServer } from '../../../../../../globals';

const Slide = ({ query, title, audio = 'Józef Werobej' }) => (
  <BlockContainer height="1000px">
    <BackgroundAnimation src={webm} mp4={mp4}>
      <ChpaterTitle marginTop="31rem">{title}</ChpaterTitle>
      <TextAnimation containerStyle={{ height: '25%', marginTop: '1rem' }} body={query.mdx.body} />
      <AudioPlayer style={{ margin: '0 auto' }} audio={[`${mediaServer}/webm/${getLangFromPath().toLowerCase()}/481werobej.webm`, `${mediaServer}/mp4/${getLangFromPath().toLowerCase()}/481werobej.mp4`]} audioImage={query.audioImage.childImageSharp.fluid} audioDescription={audio} size={2} />
    </BackgroundAnimation>
  </BlockContainer>
);

export default Slide;
